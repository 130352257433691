<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="logsType ? notificationLogsInst : notificationLogsAuto"
      :loading="loading"
      :options.sync="options"
      :server-items-length="logsType ? getNotificationLogsInstCount : getNotificationLogsAutoCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-datetime-picker
                v-model="fromDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                timeFormat="HH:mm:ss"
                dateFormat="dd-MM-yyyy"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час від"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="2" offset-md="1">
              <v-datetime-picker
                v-model="toDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                dateFormat="dd-MM-yyyy"
                timeFormat="HH:mm:ss"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час до"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:header.type="{ header }">
        <TableTitleFilter
          :values="selectedNotificationType"
          :options="pushNotificationTypesDictionary"
          :title-text="header.text"
          item-text="name"
          @select="(selected) => (selectedNotificationType = selected)"
          @ok="applyFilter('type', selectedNotificationType)"
        />
      </template>
      <template v-slot:header.is_one_time="{ header }">
        <TableTitleFilter
          :values="isOneTime"
          :options="oneTimeItems"
          :title-text="header.text"
          item-value="value"
          item-text="name"
          @select="(selected) => (isOneTime = selected)"
          @ok="applyFilter('is_one_time', isOneTime)"
        />
      </template>
      <template v-slot:header.initiator="{ header }">
        <TableTitleFilter
          :values="selectedInitiators"
          :options="initiators"
          :options-meta="initiatorsMeta"
          :options-params="initiatorsOptions"
          :title-text="header.text"
          item-value="id"
          @select="(selected) => (selectedInitiators = selected)"
          @ok="applyFilter('initiator', selectedInitiators)"
        >
          <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
          <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.balance_holder="{ header }">
        <TableTitleFilter
          :options="getBalanceHolderFilter"
          :title-text="header.text"
          @select="setSelectedBalanceHolders"
          @ok="applyFilter('balanceHolder', selectedBalanceHolders)"
        />
      </template>
      <template v-slot:header.service_point="{ header }">
        <TableTitleFilter
          :options="servicePoints"
          :title-text="header.text"
          @select="setSelectedServicePoints"
          @ok="applyFilter('servicePoint', selectedServicePoints)"
        />
      </template>
      <template v-slot:header.device_type="{ header }">
        <TableTitleFilter
          :options="connectedDevicesTypesDictionary"
          :title-text="header.text"
          item-value="name"
          item-text="description"
          @select="setSelectedDevicesType"
          @ok="applyFilter('deviceType', selectedDevicesTypes)"
        />
      </template>
      <template v-slot:header.device="{ header }">
        <TableTitleFilter
          :options="devices"
          :title-text="header.text"
          @select="setSelectedDevices"
          @ok="applyFilter('device', selectedDevices)"
        >
          <template v-slot:item="{ item }">{{ item.label }} ({{ item.type }}{{ item.value }})</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.receiver="{ header }">
        <TableTitleFilter
          :values="selectedReceivers"
          :options="users"
          :options-meta="usersMeta"
          :options-params="usersOptions"
          :title-text="header.text"
          item-value="id"
          @select="(selected) => (selectedReceivers = selected)"
          @ok="applyFilter('receiver', selectedReceivers)"
        >
          <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
          <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:item.updated_at="{ item }">{{ item.updated_at | formatDate }}</template>
      <template v-slot:item.type="{ item }">{{ getNotificationType(item.type) }}</template>
      <template v-slot:item.is_one_time="{ item }">{{ item.is_one_time ? 'Миттєве' : 'Постійне' }}</template>
      <template v-slot:item.initiator="{ item }">{{ item.initiator.last_name }} {{ item.initiator.first_name }}</template>
      <template v-slot:item.company="{ item }">{{ item.company ? item.company.name : '' }}</template>
      <template v-slot:item.receiver="{ item }">{{
        item.receiver ? `${item.receiver.last_name} ${item.receiver.first_name}` : ''
      }}</template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import sortUtils from '@/mixins/sortUtils'
import manageFiltersOptions from '@/mixins/manageFiltersOptions'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter.vue'
import { toCamelCase } from '@/helpers/toCamelCase'
import convertDate from '@/mixins/convertDate'
import isEqual from 'lodash/isEqual'

export default {
  name: 'PushNotificationsLogs',
  components: { TableTitleFilter },

  mixins: [sortUtils, manageFiltersOptions, convertDate],

  props: {
    presetIsOneTime: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Дата та час', value: 'updated_at', width: 105 },
        { text: 'Тип повідомлення', value: 'type' },
        { text: 'Ознака', value: 'is_one_time' },
        { text: 'Назва повідомлення', value: 'title' },
        { text: 'Інфо повідомлення', value: 'body' },
        { text: 'Користувач', value: 'initiator', sortFields: ['initiator.last_name', 'initiator.first_name'] },
        { text: 'Компанія', value: 'company' },
        { text: 'Орендодавець', value: 'balance_holder' },
        { text: 'Торгівельна точка', value: 'service_point' },
        { text: 'Т', value: 'terminal' },
        { text: 'Тип А', value: 'device_type' },
        { text: 'ID А', value: 'device' },
        { text: '№ А', value: 'device' },
        { text: 'Отримувач', value: 'receiver', sortFields: ['receiver.last_name', 'receiver.first_name'] },
        { text: 'Кількість отримувачів', value: 'receiver_quantity' },
        { text: 'Доставлено', value: 'received_quantity' },
        { text: 'Прочитано', value: 'read_quantity' },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      payload: {},
      isOneTime: [],
      selectedInitiators: [],
      selectedUsers: [],
      selectedReceivers: [],
      initiatorsOptions: null,
      usersOptions: null,
      selectedNotificationType: [],
      fromDate: null,
      toDate: null,
      textFieldProps: {
        hideDetails: 'auto',
        dense: true,
      },
      datePickerProps: {
        'first-day-of-week': 1,
      },
      timePickerProps: {
        format: '24hr',
        useSeconds: true,
      },
      oneTimeItems: [
        { value: 0, name: 'Постійне' },
        { value: 1, name: 'Миттєве' },
      ],
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    options: {
      handler(val, old) {
        if (!isEqual(val, old)) {
          this.paginateTo()
        }
      },
      deep: true,
    },

    presetIsOneTime(val, old) {
      if (!isEqual(val, old)) {
        this.isOneTime = val.map((item) => this.oneTimeItems[item])
        this.applyFilter('is_one_time', this.isOneTime)
      }
    },
  },

  computed: {
    ...mapState('pushNotification', [
      'notificationLogsAuto',
      'notificationLogsAutoMeta',
      'notificationLogsInst',
      'notificationLogsInstMeta',
    ]),
    ...mapState('dictionaries', ['pushNotificationTypesDictionary', 'connectedDevicesTypesDictionary']),
    ...mapState('users', ['users', 'usersMeta', 'initiators', 'initiatorsMeta']),
    ...mapGetters('pushNotification', ['getNotificationLogsAutoCount', 'getNotificationLogsInstCount']),
    ...mapGetters('companies', [
      'getUserFilter',
      'getBalanceHolderFilter',
      'getServicePointFilter',
      'getTerminalFilter',
      'getDeviceFilter',
    ]),

    currentCompanyId() {
      return this.$route.params.id
    },

    logsType() {
      return this.presetIsOneTime[0]
    },
  },

  methods: {
    ...mapActions('pushNotification', ['loadPushNotificationsLogs']),
    ...mapActions('users', ['loadUsers', 'loadInitiators']),

    async initialize() {
      this.isOneTime = this.presetIsOneTime.map((item) => this.oneTimeItems[item])
      this.initiatorsOptions = {
        loadingFunction: this.loadInitiators,
        pagination: true,
        searchParamName: 'search',
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
        },
      }
      this.usersOptions = {
        loadingFunction: this.loadUsers,
        pagination: true,
        searchParamName: 'search',
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
        },
      }
      this.isOneTime = this.presetIsOneTime.map((item) => this.oneTimeItems[item])
      await this.applyFilter('is_one_time', this.isOneTime)
    },

    async paginateTo() {
      this.payload = {
        ...this.payload,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        updatedAt: {
          fromDate: this.fromDate,
          toDate: this.toDate,
        },
        logsType: this.logsType,
      }
      this.loading = true
      try {
        await this.loadPushNotificationsLogs(this.payload)
      } finally {
        this.loading = false
      }
    },

    getNotificationType(value) {
      return this.pushNotificationTypesDictionary.find((item) => item.value === value)?.name || ''
    },

    getCSV(arr) {
      return arr.map((item) => ('value' in item ? item.value : item.id)).join(',')
    },

    async applyFilter(fieldName, selectedArr) {
      this.payload = {
        ...this.payload,
        [toCamelCase(fieldName)]: this.getCSV(selectedArr),
      }
      this.options.page = 1
      await this.paginateTo()
    },
  },
}
</script>
